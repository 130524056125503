<template>
  <div class="activity-details">
    <main>
      <div class="box">
        <div class="content">
          <div class="content-top">
            <div class="left">
              <p class="tit">邀请人</p>
              <div class="main">
                <div class="main-l">
                  <img src="../assets/plant/activity-img1.webp" alt="" />
                  <p>邀请好友充值或者购买套餐,可获得30%的收益返现</p>
                </div>
                <div class="main-l">
                  <img src="../assets/plant/activity-img2.webp" alt="" />
                  <p>好友充值多少，收益增加多少，全年不变</p>
                </div>
              </div>
              <div class="line"></div>
              <div class="footer">
                邀请好友注册亿楼平台，即有机会全年获得收益
              </div>
            </div>
            <div class="right">
              <p class="tit">被邀请人</p>
              <div class="main">
                <div class="main-l">
                  <img src="../assets/plant/activity-coin.webp" alt="" />
                  <p>
                    赠送
                    <span class="num">500</span>
                    龙币,可免费发布房源，并有效获取客户电话号码
                  </p>
                </div>
              </div>
              <div class="line"></div>
              <div class="footer">首次获得邀请，并完成注册认证的用户</div>
            </div>
          </div>
          <div class="content-bottom" v-if="loginStatus">
            <p class="title">马上邀请好友</p>
            <div class="url-box">
              <div class="url">
                <div class="p">{{ guwen }}/#/registration?{{ linkParam }}</div>
                <img
                  src="../assets/plant/activity-url.webp"
                  alt=""
                  @click="handleCopy"
                />
              </div>
              <div class="code">
                <img src="../assets/plant/activity-code.webp" alt="" />
              </div>

              <!-- 回电弹窗 -->
              <div class="code-box">
                <p>
                  <span>{{ userInfo.phone | phoneFilter }}</span
                  >送你500龙币
                </p>
                <p>微信扫码注册认证后领取</p>
                <div class="c" ref="qrCodeDiv"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-box" v-if="!loginStatus">
          <div class="btn" @click="login"></div>
        </div>
      </div>
    </main>
  </div>
</template>
  
  <script>
import { invitationLinkParam } from 'services/member'
import { isLogin } from 'services/login';
import QRCode from 'qrcodejs2';//引入生成二维码插件
import logo1 from '../assets/logo1.png'
export default {
  name: 'ActivityDetails',
  components: {
  },
  data () {
    return {
      guwen: window.guwen,
      loginStatus: false,
      userInfo: {},
      linkParam: ''
    };
  },
  computed: {
  },
  mounted () {
    this.judgingLogin()
  },
  methods: {
    judgingLogin () {
      isLogin().then(res => {
        if (res.data.status === 200) {
          this.loginStatus = res.data.data
          if (!res.data.data) {
            localStorage.removeItem('userInfo')
          } else {
            // 获取用户信息
    if (localStorage.userInfo) this.userInfo = JSON.parse(localStorage.userInfo)
            this.$bus.$on('getUserInfo', userInfo => {

              this.userInfo = userInfo
            })
            this.openCode(`${window.inviteRegisteir}${this.linkParam}&phone=${this.userInfo.phone}`)
            this.getLink()
          }
        }
      })
    },
    getLink () {
      invitationLinkParam().then(res => {
        if (res.data.status === 200) {
          this.linkParam = res.data.data.linkParam
        }
      })
    },
    login () {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        window.location.href = window.loginIp + "/#/ticket?from=" + window.location.hash + '&jl';
      }, 500)
    },
    openCode (text) {
      this.$nextTick(() => {
        this.$refs.qrCodeDiv.innerHTML = '';//二维码清除
        let qrcode = new QRCode(this.$refs.qrCodeDiv, {
          text,//二维码链接，参数是否添加看需求
          width: 220,//二维码宽度
          height: 220,//二维码高度
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.H //容错率，L/M/H
        });
        let logo = new Image();
        logo.crossOrigin = 'Anonymous';
        logo.src = logo1
        let con = this.$refs.qrCodeDiv;
        logo.onload = function () {
          let qrImg = qrcode._el.getElementsByTagName('img')[0];
          let canvas = qrcode._el.getElementsByTagName('canvas')[0];
          var ctx = canvas.getContext("2d");
          ctx.drawImage(logo, 220 * 0.5 - 22, 220 * 0.5 - 22, 44, 44);
          qrImg.src = canvas.toDataURL();
          con.appendChild(qrImg);
        }
      })
    },
    handleCopy () {
      let copy_text = `${this.guwen}/#/registration?${this.linkParam}`; // 拿到想要复制的值
      let input_dom = document.createElement('input'); // 创建input元素
      input_dom.value = copy_text; // 添加需要复制的内容到value属性
      document.body.appendChild(input_dom); // 向页面底部追加输入框
      input_dom.select(); // 选择input元素
      document.execCommand("Copy"); // 执行复制命令
      this.$message({
        type: 'success',
        message: '复制成功'
      })
      // 复制之后再删除元素，否则无法成功赋值
      input_dom.remove(); // 删除动态创建的节点
    },
  }
};
  </script>
  <style lang="less" scoped>
.activity-details {
  width: 100%;
  main {
    width: 100%;
    height: 1840px;
    background: url(../assets/plant/activity-bg.webp);
    position: relative;
    .box {
      width: 100%;
      padding-top: 879px;
      .content {
        padding: 105px 135px;
        margin: 0 330px;
        background: rgba(255, 252, 235, 0.32);
        border: 1px solid #fff1cd;
        border-radius: 20px;
        &-top {
          width: 100%;
          height: 280px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            height: 100%;
            width: calc(50% - 51px);
            background: url(../assets/plant/activity-Inviter.webp);
            background-size: 100% 100%;
            .main {
              padding: 12px 57px 22px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &-l {
                width: calc(50% - 20px);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img {
                  width: 88px;
                  height: 90px;
                }
                p {
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #ffffff;
                  line-height: 22px;
                  text-align: center;
                }
              }
            }
            .line {
              width: 100%;
              height: 1px;
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.01) 1%,
                #ffffff 46%,
                rgba(255, 255, 255, 0) 100%
              );
            }
            .footer {
              text-align: center;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.8);
              line-height: 36px;
            }
          }
          .right {
            height: 100%;
            width: calc(50% - 51px);
            background: url(../assets/plant/activity-Invitee.webp);
            background-size: 100% 100%;
            .main {
              padding: 12px 57px 22px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &-l {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img {
                  width: 88px;
                  height: 90px;
                  margin-bottom: 18px;
                }
                p {
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #ffffff;
                  line-height: 22px;
                  text-align: center;
                  .num {
                    font-size: 28px;
                    font-family: DIN;
                    font-weight: bold;
                    color: #ffe064;
                    line-height: 22px;
                  }
                }
              }
            }
            .line {
              width: 100%;
              height: 1px;
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.01) 1%,
                #ffffff 46%,
                rgba(255, 255, 255, 0) 100%
              );
            }
            .footer {
              text-align: center;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.8);
              line-height: 36px;
            }
          }
          .tit {
            text-align: center;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            line-height: 44px;
          }
        }
        &-bottom {
          padding-top: 90px;
          position: relative;
          .title {
            font-size: 44px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            text-align: center;
            margin-bottom: 34px;
          }
          .url-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .url {
              width: calc(100% - 120px);
              height: 100px;
              background: rgba(250, 48, 7, 0.4);
              border-radius: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 15px 20px;
              .p {
                width: calc(100% - 240px);
                padding-left: 26px;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
                padding-right: 20px;
                word-break: break-all;
              }
              img {
                width: 240px;
                height: 70px;
                cursor: pointer;
              }
            }
            .code {
              width: 100px;
              height: 100px;
              background: rgba(250, 48, 7, 0.4);
              border-radius: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              img {
                width: 56px;
                height: 56px;
              }
            }
            .code:hover + .code-box {
              opacity: 1;
            }
            .code-box {
              opacity: 0;
              position: absolute;
              top: 185px;
              right: -355px;
              width: 341px;
              padding: 35px 52px;
              background: rgba(255, 255, 255, 0.9);
              border: 1px solid #ffffff;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              p {
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 22px;
                margin-bottom: 12px;
                span {
                  color: #ec6600;
                }
              }
              .c {
                width: 220px;
                height: 220px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .btn-box {
        margin-top: 137px;
        padding-bottom: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          width: 660px;
          height: 122px;
          background: url(../assets/plant/activity-btn.webp);
          background-size: 100% 100%;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
  